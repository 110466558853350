/* eslint-env es6*/
/* eslint-disable no-use-before-define */
/* global _etmc */
'use strict';
var base = require('base/product/base');
var focusHelper = require('../components/focus');

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {jQuery} $productContainer - DOM element for a given product.
 * @param {string} attributeType - the attribute clicked (e.g size, color, quantity)
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 */
function handleVariantResponse(response, $productContainer, attributeType) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    if (attributeType === 'color') {
        // Update primary images
        var productImages = response.product.images;
        var primaryImageUrls = productImages.large;
        var hiResPrimaryImageUrls = productImages['hi-res'] !== 'undefined' ? productImages['hi-res'] : primaryImageUrls;
        createCarousel(primaryImageUrls, $productContainer, hiResPrimaryImageUrls);
        createThumbnailCarousel(primaryImageUrls, $productContainer);
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length ? $('.prices .price', $productContainer) : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer
        .find('.promotions')
        .empty()
        .html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product,
            $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global')
            .trigger('product:updateAddToCart', {
                product: response.product,
                $productContainer: $productContainer
            })
            .trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer
        .find('.main-attributes')
        .empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el)
              .closest('.product-detail')
              .find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieve contextual size selector
 * @param {jquery} $el - DOM container for the relevant sizes
 * @return {jquery} - sizes selector DOM container
 */
function getSizesSelector($el) {
    return $($el)
        .closest('.product-detail')
        .find('.size-selections');
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function(attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';

    attr.values.forEach(function(attrValue) {
        var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.data('url', attrValue.url).removeAttr('disabled');

        if (!attrValue.selectable && !attrValue.enableBackInStockNotification) {
            $attrValue.attr('disabled', true);
        }
        if (!attrValue.selectable && attrValue.enableBackInStockNotification) {
            if ($attrValue.hasClass('size-attribute')) {
                $attrValue.addClass('show-backinstock-module');
            }
        }
    });
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Array} hiResImags - array of images
 */
function createCarousel(imgs, $productContainer, hiResImags) {
    var carousel = $productContainer.find('.carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel)
        .empty()
        .append(
            '<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' +
                carouselId +
                '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' +
                $(carousel).data('prev') +
                '</span></a><a class="carousel-control-next" href="#' +
                carouselId +
                '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' +
                $(carousel).data('next') +
                '</span></a>'
        );
    for (var i = 0; i < imgs.length; i++) {
        $(
            '<div id="carousel-item-' +
                i +
                '" class="carousel-item" data-index="' +
                i +
                '"><img src="' +
                imgs[i].url +
                '" class="d-block img-fluid" data-image="' +
                i +
                '" data-zoom="' +
                hiResImags[i].url +
                '" alt="' +
                imgs[i].alt +
                ' image number ' +
                parseInt(imgs[i].index, 10) +
                '" title="' +
                imgs[i].title +
                '" itemprop="image" /></div>'
        ).appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item'))
        .first()
        .addClass('active');
    $($(carousel).find('.carousel-indicators > li'))
        .first()
        .addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
}

/**
 * Dynamically creates thumbnail carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createThumbnailCarousel(imgs, $productContainer) {
    var thumbnailCarousel = $productContainer.find('.thumbnail-carousel');

    // clear the active styling for the current selected thumbnail
    thumbnailCarousel.find('.thumbnail-carousel__item').removeClass('thumbnail-carousel__item--active');

    var template = '<ul class="thumbnail-carousel__items">';

    for (var j = 0; j < imgs.length; j++) {
        template += `<li class="thumbnail-carousel__item"><a href="javascript:void(0)" data-href="#carousel-item-${j}"><img src="${imgs[j].url}" data-index="${j}" class="d-block img-fluid" alt="${imgs[j].alt}" title="${imgs[j].title}" itemprop="image" tabindex="0" /></a></li>`;
    }

    template += '</ul>';

    $(thumbnailCarousel)
        .empty()
        .append(template);

    var setActiveThumbnail = function(delayInMS) {
        return new Promise(function(resolve) {
            setTimeout(resolve, delayInMS);
        });
    };

    var $thumbnailCarouselItems = document.querySelectorAll('.thumbnail-carousel__item');

    var imagesLoaded = 0;
    var observer = new IntersectionObserver(function(entries) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                imagesLoaded++;
            }
        });

        if (
            imagesLoaded > 2 &&
            $(entries[0].target)
                .find('img')
                .is(':visible')
        ) {
            setActiveThumbnail(2000).then(function() {
                if (!$(entries[0].target).hasClass('thumbnail-carousel__item--active')) {
                    $(entries[0].target).addClass('thumbnail-carousel__item--active');
                }
            });
        }
    }, {});

    $thumbnailCarouselItems.forEach(function(carousel) {
        observer.observe(carousel);
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function(attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
            updateSizeUrl(attrs[1], $productContainer);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Routes the handling of size attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attr - Size attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateSizeUrl(attr, $productContainer) {
    var sizesHtml = attr.values
        .map(function(size) {
            var selectable = size.selectable ? 'selectable' : 'unselectable';
            var selected = size.selected ? 'selected' : '';

            return (
                '<button class="size-attribute" role="radio" aria-label="' +
                size.id +
                '" aria-checked ="' +
                size.selected +
                '" data-url="' +
                size.url +
                '" data-attr-value="' +
                size.value +
                '" data-value-id="' +
                size.id +
                '">' +
                '<span class="size-value ' +
                selectable +
                ' ' +
                selected +
                '" data-attr-value="' +
                size.value +
                '">' +
                size.value +
                '</span>' +
                '</button>'
            );
        })
        .join('');
    getSizesSelector($productContainer)
        .empty()
        .html(sizesHtml);
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    var availabiityContainer = $productContainer.find('.product-description .availability');
    if (!response.product.available) {
        if (availabiityContainer.hasClass('hide-incart')) {
            availabiityContainer.removeClass('hide-incart');
        }
    } else if (!availabiityContainer.hasClass('hide-incart')) {
        availabiityContainer.addClass('hide-incart');
    }
    availabiityContainer.data('available', response.product.available);
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else if (response.product.availability.classType === 'qty-err') {
        availabilityValue = '<li><div>Please select a lesser quantity to update</div></li>';
    } else {
        availabilityMessages.forEach(function(message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function(attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function(attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': ' + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer
        .find('.product-options')
        .empty()
        .html(optionsHtml);
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities
            .map(function(quantity) {
                var selected = quantity.selected ? ' selected ' : '';
                return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' + selected + '>' + quantity.value + '</option>';
            })
            .join('');
        getQuantitySelector($productContainer)
            .empty()
            .html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {string} attributeType - the attribute clicked (e.g size, color, quantity)
 */
function attributeSelect(selectedValueUrl, $productContainer, attributeType) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: `${selectedValueUrl}&variationType=${attributeType}`,
            method: 'GET',
            success: function(data) {
                if (data.redirect) {
                    window.location.href = data.redirect;
                    return;
                }
                handleVariantResponse(data, $productContainer, attributeType);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect', { data: data, container: $productContainer, attributeType });
            },
            complete: function() {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body')
        .spinner()
        .start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="' +
        data.maxBonusItems +
        '"' +
        'data-UUID="' +
        data.uuid +
        '"' +
        'data-pliUUID="' +
        data.pliUUID +
        '"' +
        'data-addToCartUrl="' +
        data.addToCartUrl +
        '"' +
        'data-pageStart="0"' +
        'data-pageSize="' +
        data.pageSize +
        '"' +
        'data-moreURL="' +
        data.showProductsUrlRuleBased +
        '"' +
        'data-bonusChoiceRuleBased="' +
        data.bonusChoiceRuleBased +
        '">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <span class="">' +
        data.labels.selectprods +
        '</span>' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body')
        .spinner()
        .start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function(response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
        },
        complete: function() {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {jQuery} $addToCartBtn = add to cart button clicked
 */
function handlePostCartAdd(response, $addToCartBtn) {
    $('.minicart').trigger('count:update', response);

    var $messageQtyError = $addToCartBtn.closest('.product-detail').find('.message.qty-err');
    var $messageNoStock = $addToCartBtn.closest('.product-detail').find('.message.no-stock');
    if ($messageQtyError.length || $messageNoStock.length) {
        $addToCartBtn.prop('disabled', true);
        return;
    }
    // show add to cart toast
    if (response.newBonusDiscountLineItem && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function() {
        childProducts.push({
            pid: $(this)
                .find('.product-id')
                .text(),
            quantity: parseInt(
                $(this)
                    .find('label.quantity')
                    .data('quantity'),
                10
            )
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function() {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]').data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        })
        .toArray();

    return JSON.stringify(options);
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function(data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function() {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function() {
            $('#chooseBonusProductModal')
                .siblings()
                .attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function() {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function() {
            $('#chooseBonusProductModal')
                .siblings()
                .attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function() {
        $('body').on('keydown', '#chooseBonusProductModal', function(e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function() {
        $(document).on('click', '[data-attr="color"] button', function(e) {
            e.preventDefault();

            if (
                $(this).attr('disabled') ||
                $(this)
                    .find('.swatch-value')
                    .hasClass('selected')
            ) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            attributeSelect($(this).attr('data-url'), $productContainer, 'color');
        });
    },

    selectAttribute: function() {
        $(document).on('change', 'select[class*="select-"], .options-select', function(e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer, 'quantity');
        });
    },

    availability: function() {
        $(document).on('change', '.quantity-select', function(e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this)
                    .closest('.modal-content')
                    .find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect(
                    $(e.currentTarget)
                        .find('option:selected')
                        .data('url'),
                    $productContainer,
                    'quantity'
                );
            }
        });
    },
    updateProductPromotions: function($el, promotions) {
        if ($el.length && promotions.length) {
            $el.empty();

            var template = '';

            promotions.forEach(promotion => {
                template += `<div class="badge-promotional bfx-remove-element">${promotion.calloutMsg}</div>`;
            });
            $el.html(template);
        }
    },
    addToCart: function() {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function() {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var $thisBtnAdd = $(this);

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                var $messageQtyError = $(this)
                    .closest('.product-detail')
                    .find('.message.qty-err');
                var $messageNoStock = $(this)
                    .closest('.product-detail')
                    .find('.message.no-stock');
                if ($messageQtyError.length || $messageNoStock.length) {
                    $.spinner().stop();
                    $(this).prop('disabled', true);
                    return;
                }

                setPids = [];

                $('.product-detail').each(function() {
                    if (
                        !$(this).hasClass('product-set-detail') &&
                        $(this).find('.size-selections .size-attribute .size-value.selected') &&
                        $(this).find('.quantity-select').length
                    ) {
                        setPids.push({
                            pid: $(this)
                                .find('.product-id')
                                .text(),
                            qty: $(this)
                                .find('.quantity-select')
                                .val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = base.getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this)
                    .closest('.quick-view-dialog')
                    .find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: base.getQuantitySelected($(this))
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function(data) {
                        handlePostCartAdd(data, $thisBtnAdd);
                        data.addedPid = pid;
                        if (!data.error) {
                            $('body').trigger('product:afterAddToCart', data);
                        }
                        base.miniCartReportingUrl(data.reportingURL);
                        if (data.sfmcCartInfo && typeof _etmc !== 'undefined') {
                            _etmc.push(data.sfmcCartInfo);
                        }
                    },
                    complete: function() {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    selectBonusProduct: base.selectBonusProduct,
    removeBonusProduct: base.removeBonusProduct,
    enableBonusProductSelection: base.enableBonusProductSelection,
    showMoreBonusProducts: base.showMoreBonusProducts,
    addBonusProductsToCart: base.addBonusProductsToCart,
    getPidValue: base.getPidValue,
    getQuantitySelected: base.getQuantitySelected,
    miniCartReportingUrl: base.miniCartReportingUrl,
    backToTop: function() {
        const $el = $('.back-to-top');

        if ($el.length > 0) {
            $el.on('click', () => {
                $('html, body').animate({ scrollTop: 0 }, 500);
            });
        }
    }
};
