var baseHelper = require('../product/base');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;
    pid = $el.data('pid');
    return pid;
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body')
        .spinner()
        .start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="' +
        data.maxBonusItems +
        '" ' +
        'data-UUID="' +
        data.uuid +
        '" ' +
        'data-pliUUID="' +
        data.pliUUID +
        '" ' +
        'data-addToCartUrl="' +
        data.addToCartUrl +
        '" ' +
        'data-pageStart="0" ' +
        'data-pageSize="' +
        data.pageSize +
        '" ' +
        'data-moreURL="' +
        data.showProductsUrlRuleBased +
        '" ' +
        'data-bonusChoiceRuleBased="' +
        data.bonusChoiceRuleBased +
        '">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <span class="">' +
        data.labels.selectprods +
        '</span>' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body')
        .spinner()
        .start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
        },
        complete: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    // show add to cart toast
    if (response.newBonusDiscountLineItem && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    }
}

module.exports = {
    initAddToBag: function () {
        var addtoBag = function (e) {
            if ($(this).closest('.plp-atc').length) {
                e.stopImmediatePropagation();
            }

            var addToCartUrl;
            var pid;
            var pidsObj;
            var $this = $(this);

            $this.addClass('focusable'); // Make this element the only item to accept :focus styling while this size is being added to cart to avoid confusion

            if ($(this).closest('.js-product-tile-pd').length === 0) {
                $this.closest('.product-tile').removeClass('active-hover');
            }
            $this
                .closest('.product-tile')
                .find('.row.add-to-cart[data-attr="size"]')
                .not('.hidden')
                .removeClass('hover-active');

            pid = getPidValue($(this));

            addToCartUrl = $(this)
                .closest('.add-to-cart')
                .data('addtocartUrl');

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: [],
                quantity: 1
            };

            form.options = [];

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        // reload cart page if plp is on cart page
                        if ($('.cart.cart-page').length > 0) {
                            window.location.href = data.redirectUrl;
                        } else {
                            handlePostCartAdd(data);
                            data.addedPid = pid;
                            $('body').trigger('product:afterAddToCart', data);
                            $this
                                .closest('.product-tile')
                                .find('.js-plp-add-to-bag.desktop')
                                .addClass('hidden');
                            $this.closest('.add-to-cart[data-attr="size"]').addClass('hidden');
                            $this
                                .closest('.product-tile')
                                .find('.add-to-cart-variation-group.plp-atc.collapsible-xs')
                                .removeClass('active');
                            $this
                                .closest('.product-tile')
                                .find('.row.add-to-cart[data-attr="size"]')
                                .addClass('hover-active');
                            baseHelper.miniCartReportingUrl(data.reportingURL);
                        }
                    },
                    error: function () {
                        $this
                            .closest('.product-tile')
                            .find('.row.add-to-cart[data-attr="size"]')
                            .addClass('hover-active');
                    },
                    complete: function () {
                        $this.removeClass('focusable'); // Make other elements focusable after size has been added to cart
                        $('.js-size-select-show').removeClass('hidden');
                    }
                });
            }
        };

        $('body')
            .on('click', '.plp-atc .size-selections .size-attribute', addtoBag)
            .on('mouseleave', '.add-to-cart-variation-group', function () {
                if (
                    $(this)
                        .closest('.product-tile')
                        .find('.js-plp-add-to-bag')
                        .css('position') === 'absolute' &&
                    $(this).closest('.js-product-tile-pd').length === 0
                ) {
                    $(this)
                        .find('.add-to-cart[data-attr="size"]')
                        .addClass('hidden');
                    $(this)
                        .closest('.product-tile')
                        .find('.image-container')
                        .removeClass('hovered');
                }
            })
            .on('mouseleave', '.plp-search-results .product-tile, .products-container .product-tile, .product-page-bottom-section .product-tile', function () {
                $(this).addClass('active-hover');
                $(this)
                    .find('.js-plp-add-to-bag')
                    .removeClass('hidden');
            })
            .on('mouseleave', '.js-product-tile-pd .js-product-tile.active-hover, .container-fluid.search-results.plp-search-results .active-hover', function(e) {
                    $(this)
                        .find('.add-to-cart[data-attr="size"]')
                        .addClass('hidden');
                    $(this)
                        .find('.image-container')
                        .removeClass('hovered');
            })
            .on(
                'mouseenter',
                '.container-fluid.search-results.plp-search-results .active-hover .js-plp-add-to-bag, .products-container .active-hover .js-plp-add-to-bag, .product-page-bottom-section .active-hover .js-plp-add-to-bag',
                function (e) {
                    if ($(this).css('position') === 'absolute') {
                        var vgroupid = $(e.target)
                            .closest('.product-tile')
                            .find('.color-swatches button.color-attribute.active')
                            .data('id');
                        $(e.target)
                            .closest('.product')
                            .find('.row.add-to-cart.hidden[data-group-pid="' + vgroupid + '"]')
                            .removeClass('hidden');
                        $(e.target)
                            .closest('.product')
                            .find('.image-container')
                            .addClass('hovered');
                    }
                }
            )
            .on(
                'mouseenter',
                '.container-fluid.search-results.plp-search-results .active-hover .product-detail, .products-container .active-hover .product-detail, .product-page-bottom-section .active-hover .product-detail',
                function (e) {
                        var vgroupid = $(e.target)
                            .closest('.product-tile')
                            .find('.color-swatches button.color-attribute.active')
                            .data('id');
                        $(e.target)
                            .closest('.product')
                            .find('.row.add-to-cart.hidden[data-group-pid="' + vgroupid + '"]')
                            .removeClass('hidden');
                        $(e.target)
                            .closest('.product')
                            .find('.image-container')
                            .addClass('hovered');
                }
            )
            .on(
                'mouseenter',
                '.js-product-tile-pd .js-product-tile.active-hover',
                function(e) {
                    var vgroupid = $(e.currentTarget)
                        .find('.color-swatches button.color-attribute.active')
                        .data('id');
                    $(e.currentTarget)
                        .closest('.product')
                        .find('.row.add-to-cart.hidden[data-group-pid="' + vgroupid + '"]')
                        .removeClass('hidden');
                }
            )
            .on(
                'click',
                '.container-fluid.search-results.plp-search-results .js-plp-add-to-bag, .products-container .plp-atc .js-plp-add-to-bag, .product-page-bottom-section .plp-atc .js-plp-add-to-bag',
                function (e) {
                    e.preventDefault();
                    var vgroupid;
                    if (
                        $(this)
                            .closest('.product-tile')
                            .find('.js-plp-add-to-bag')
                            .css('position') === 'relative'
                    ) {
                        vgroupid = $(e.target)
                            .closest('.product-tile')
                            .find('.color-swatches button.color-attribute.active')
                            .data('id');
                        if (vgroupid) {
                            $(e.target)
                                .closest('.product')
                                .find('.row.add-to-cart[data-attr="size"]')
                                .not('[data-group-pid="' + vgroupid + '"]')
                                .addClass('hidden');
                            $(e.target)
                                .closest('.product')
                                .find('.row.add-to-cart[data-attr="size"][data-group-pid="' + vgroupid + '"]')
                                .toggleClass('hidden');
                            $(e.target)
                                .closest('.product')
                                .find('.image-container')
                                .removeClass('hovered');
                        }
                    }
                    if (!vgroupid) {
                        var $colorAlert = $(e.target)
                            .closest('.add-to-cart-variation-group')
                            .find('.color.alert');
                        if ($colorAlert.length) {
                            $colorAlert.remove();
                        }
                        $colorAlert = $('<span class="color alert" role="alert">Please select color.</span>');
                        $(e.target)
                            .closest('.add-to-cart-variation-group')
                            .prepend($colorAlert);
                        setTimeout(function () {
                            $colorAlert.remove();
                        }, 5000);
                        $(e.target)
                            .closest('.add-to-cart-variation-group')
                            .removeClass('active');
                    }
                }
            )
            .on(
                'click',
                '.container-fluid.search-results.plp-search-results button.color-attribute, .products-container button.color-attribute, .product-recommendation-details button.color-attribute',
                function (e) {
                    e.preventDefault();
                    if (
                        $(this)
                            .closest('.product-tile')
                            .find('.js-plp-add-to-bag')
                            .css('position') === 'relative'
                    ) {
                        $(e.target)
                            .closest('.product')
                            .find('.row.add-to-cart[data-attr="size"]')
                            .addClass('hidden');
                        $(e.target)
                            .closest('.product')
                            .find('.image-container')
                            .removeClass('hovered');
                    }
                    $(this)
                        .closest('.tile-body')
                        .prev('.add-to-cart-variation-group')
                        .removeClass('active');
                }
            )
            .off('click', '.js-product-tile-pd, js-product-tile-container')
            .on('click', '.js-size-select-show', function(e) {
                $('.js-size-select-show').removeClass('hidden');
                $(this).addClass('hidden');
                $(this).closest('.js-product-tile-container').find('.add-to-cart').removeClass('hidden');
                // Non-Page Designer PLP Mobile Add to Cart Icon
                if ($(this).closest('.js-product-tile-container').length === 0) {
                    $('.add-to-cart').addClass('hidden');
                    $('.non-pd.add-to-cart-variation-group.current-tile').removeClass('current-tile');
                    // TODO refactor this into a helpful function so we don't have duplicate code for .js-plp-add-to-bag
                    e.preventDefault();
                    var vgroupid;
                    if (
                        $(this)
                            .closest('.product-tile')
                            .find('.js-plp-add-to-bag')
                            .css('position') === 'relative'
                    ) {
                        vgroupid = $(e.target)
                            .closest('.product-tile')
                            .find('.color-swatches button.color-attribute.active')
                            .data('id');
                        if (vgroupid) {
                            $(e.target)
                                .closest('.product')
                                .find('.row.add-to-cart[data-attr="size"]')
                                .not('[data-group-pid="' + vgroupid + '"]')
                                .addClass('hidden');
                            $(e.target)
                                .closest('.product')
                                .find('.row.add-to-cart[data-attr="size"][data-group-pid="' + vgroupid + '"]')
                                .toggleClass('hidden');
                            $(e.target)
                                .closest('.product')
                                .find('.image-container')
                                .removeClass('hovered');
                            $(e.target)
                                .closest('.product')
                                .find('.non-pd.add-to-cart-variation-group')
                                .removeClass('d-none d-lg-flex')
                                .addClass('current-tile');
                        }
                    }
                    if (!vgroupid) {
                        var $colorAlert = $(e.target)
                            .closest('.add-to-cart-variation-group')
                            .find('.color.alert');
                        if ($colorAlert.length) {
                            $colorAlert.remove();
                        }
                        $colorAlert = $('<span class="color alert" role="alert">Please select color.</span>');
                        $(e.target)
                            .closest('.add-to-cart-variation-group')
                            .prepend($colorAlert);
                        setTimeout(function () {
                            $colorAlert.remove();
                        }, 5000);
                        $(e.target)
                            .closest('.add-to-cart-variation-group')
                            .removeClass('active');
                    }
                }
            })
            .on('click', '.js-size-select-close', function() {
                $(this).closest('.js-product-tile-container').find('.js-size-select-show').removeClass('hidden');
                $(this).closest('.js-product-tile-container').find('.add-to-cart').addClass('hidden');
                $(this).closest('.js-product-tile').find('.add-to-cart').addClass('hidden');
            })
            .on('click', function (e) {
                $('.non-pd.add-to-cart-variation-group:not(.current-tile)').addClass('d-none d-lg-flex');
                if (!$(e.target).hasClass('accordion-icon') && (!($(e.target).hasClass('js-size-select-show') ||
                    $(e.target).parent().hasClass('js-size-select-show') ||
                    $(e.target).hasClass('js-plp-add-to-bag')))
                ) {
                    var variationGroup = $('.add-to-cart-variation-group');
                    if (variationGroup && variationGroup.hasClass('active')) {
                        variationGroup.removeClass('active');
                        variationGroup.find('.js-plp-add-to-bag').attr('aria-expanded', 'false');
                        variationGroup.find('.add-to-cart').addClass('hidden');
                    }

                    $('.js-size-select-show').removeClass('hidden');
                    $('.add-to-cart').addClass('hidden');
                }
            });
    },
    changeColorSwatch: $(document).on('click', '.product-tile .color-swatches button', function () {
        var $this = $(this);
        var container = $this.closest('.product-tile');

        container.find('button').removeClass('active');
        container.find('button.color-attribute').each(function () {
            $(this).attr('aria-checked', false);
        });
        $this.addClass('active').attr('aria-checked', true);
        var listprice = $this.data('listprice');
        var salesprice = $this.data('salesprice');
        var salesBeforePromo = $this.data('salespricebeforepromo');
        var imgfront = $this.data('imgfront');
        var imgfrontalt = $this.data('imgfrontalt');
        var imgback = $this.data('imgback');
        var imgbackalt = $this.data('imgbackalt');
        var percentageoff = $this.data('percentageoff');
        var isNew = $this.data('isnew');
        var isBestSeller = $this.data('isbestseller');
        var exclusiveBadge = $this.data('exclusivebadge');
        var exclusiveBadgeID = $this.data('exclusivebadgeid');
        var salesBadge = $this.data('salesbadge');
        var id = $this.data('id');
        var defaultVariantId = $this.data('defaultvariantid');
        var hasSalePricebook = $this.data('hassalepricebook');
        var promotions = $this.data('promotions');
        var url = $this.data('url');
        var quickviewUrl = '/on/demandware.store/Sites-aando-Site/default/Product-ShowQuickView?pid=' + id;
        var name = $this.data('varname');
        container.find('.link').text(name);
        container.find('.front-card').attr({ src: imgfront, alt: imgfrontalt });
        container.find('.back-card').attr({ src: imgback, alt: imgbackalt });
        container.find('a').attr({ href: url });
        container.find('a.quickview').attr({ href: quickviewUrl });

        container.find('.bfx-sku').text(defaultVariantId);
        if (!listprice) {
            container.find('.strike-through').hide();
            container
                .find('.bfx-list-price')
                .attr({ content: salesprice.value })
                .text(salesprice.formatted);
            container.find('.percent-value').text('');
        } else {
            var isHidden = container.find('.strike-through').css('display');
            if (isHidden === 'none') {
                container.find('.strike-through').css('display', '');
            }

            if (container.find('.bfx-sale-price').length) {
                container
                    .find('.bfx-list-price')
                    .attr({ content: listprice.value })
                    .text(listprice.formatted);
                container.find('.percent-value').text(percentageoff);
            } else {
                var listPriceText =
                    '<del>' +
                    '<span class="strike-through list">' +
                    '<span class="value bfx-price bfx-original-price bfx-list-price" content="' +
                    listprice.value +
                    '">' +
                    listprice.formatted +
                    '</span>' +
                    '</span>' +
                    '</del>';
                var salesPriceText =
                    '<span class="sales"><span class="value bfx-price bfx-sale-price" content="' + salesprice.value + '">' + salesprice.formatted + '</span>';
                var percentText = '<span class="sales-percentage badge-discount"><span class="percent-value">' + percentageoff + '</span></span></span>';

                if (!hasSalePricebook) {
                    container.find('.price-section').html(salesPriceText);
                } else {
                    container.find('.price-section').html(listPriceText + salesPriceText);
                }
            }
            container
                .find('.bfx-sale-price')
                .attr({ content: salesprice.value })
                .text(salesprice.formatted);
        }

        if (!hasSalePricebook) {
            container.find('.strike-through').hide();
            container.find('.percent-value').text('');
        }
        // eslint-disable-next-line no-unused-expressions
        salesprice &&
            container
                .find('.bfx-sale-price')
                .attr({ content: salesprice.value })
                .text(salesprice.formatted);
        // eslint-disable-next-line no-unused-expressions
        isNew &&
            container
                .find('.badge-product')
                .show()
                .html('<span>New Arrivals</span>');
        // eslint-disable-next-line no-unused-expressions
        isBestSeller &&
            container
                .find('.badge-product')
                .show()
                .html('<span>Best Seller</span>');

        if (!isNew && !isBestSeller) {
            container.find('.badge-product').hide();

            if (salesBadge) {
                container.find('.badge-product').show();
            }
        }

        var salesBeforePromoContainer = container.find('.sales-before-promo');
        if (salesBeforePromo) {
            if (salesBeforePromoContainer.length) {
                salesBeforePromoContainer.show().text(salesBeforePromo.formatted);
            } else {
                $(`<span class="strike-through sales-before-promo">${salesBeforePromo.formatted}</span>`).insertAfter(container.find('.list'));
            }
        } else if (salesBeforePromoContainer.length) {
            salesBeforePromoContainer.hide();
        }

        // display exclusive badge when not empty and if sales badge is empty
        container.find('.badge-product').removeClass('badge-exclusive');

        if (exclusiveBadge && !salesBadge) {
            container.find('.badge-product').show();
            container.find('.badge-product').addClass('badge-exclusive');
            var badge;

            if (!container.find('.badge-product').length) {
                badge = '<div class="badge-product"><span>' + exclusiveBadge + '</span></div>';
                container.find('.product-detail').append(badge);
            } else {
                badge = '<span>' + exclusiveBadge + '</span>';
                container.find('.badge-product').html(badge);
            }

            // hide the 'New to Sale' badge for international users
            var newToSale = window.Resources.NEW_TO_SALE.split(' ').join('');
            if (window.SitePreferences.IS_INTERNATIONAL && exclusiveBadgeID === newToSale.toLowerCase()) {
                container.find('.badge-product').hide();
            }
        }

        /* re-renders the applicable promotions if the product has active product-level promotions */
        var $targetEl = container.find('.applicable-promotions');
        if (promotions && promotions.length > 0) {
            baseHelper.updateProductPromotions($targetEl, promotions);
        } else {
            $targetEl.empty();
        }
    }),
    onSizeClick: $('body').on('click', '.product-page-bottom-section .size-value, .products-container .size-value', function () {
        if ($(this).hasClass('selected') === true) {
            $(this).removeClass('selected');
        } else {
            $(this).addClass('selected');
        }
    })
};
